
import { Vue, Component } from 'vue-property-decorator'
import layout from '@/views/FrameBase/Main.vue'
import FaqItem from './components/FaqItem.vue'
import HelpFooter from './components/HelpFooter.vue'
import { helps } from './helps'
import { siteConfig } from '@/config/site'
import { lang } from '@/lang'

@Component({
  components: {
    layout,
    FaqItem,
    HelpFooter
  }
})
export default class FrameBase extends Vue {
  private get helps () {
    return helps[siteConfig.lang as 'CN' | 'EN']
  }

  private get text () {
    return {
      a: lang('HELP_CENTER_TITLE'),
      b: lang('HELP_CENTER_TIP')
    }
  }

  private goDetail (category: number, index:number) {
    this.$router.push(`/help/detail?category=${category}&index=${index}`)
  }
}
