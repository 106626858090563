
import { lang } from '@/lang'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class HelpFooter extends Vue {
  private goBack () {
    this.$router.push('/help')
  }
}
