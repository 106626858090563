
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class FaqItem extends Vue {
  @Prop({ type: String, default: '' })
  private category!: string

  private active = false

  private toggle () {
    this.active = !this.active
  }
}
