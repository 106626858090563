const EN = [
  {
    category: 'How to register a PNS domain',
    list: [
      {
        title: 'What is the price of the PNS domain?',
        text: 'For domain names in the length of 8–10 digits, the regular price is 25USD, containing: the registration fee of 20USD and the annual fee of 5USD.'
      },
      {
        title: 'How to add the Moonbeam network?',
        text: 'Kindly refer to: <a href="https://medium.com/moonbeam-network/how-to-connect-metamask-to-moonbeam-10a70d631735">https://medium.com/moonbeam-network/how-to-connect-metamask-to-moonbeam-10a70d631735</a>'
      },
      {
        title: 'How to add MetaMask to chrome?',
        text: 'Kindly refer to: <a href="https://metamask.io/faqs/">https://metamask.io/faqs/</a>'
      },
      {
        title: 'What’s the limitation of the PNS domain?',
        text: '8-digits or above domain names are open for registration. Valid characters: English letters (not case-sensitive), numbers and ligatures (hyphen, -), ligatures cannot be used consecutively and cannot be used at the beginning or end.\n' +
          '\n' +
          'For example, valid names: hello-world.dot | helloworld.dot\n' +
          '\n' +
          'invalid names: hello—world.dot | -helloworld.dot | helloworld-.dot'
      },
      {
        title: 'Which wallet can be used to buy the PNS domain, and which token will be used?',
        text: 'PNS supports MetaMask wallet and payment by GLMR token. Please add the Moonbeam network and prepare GLMR in advance if you want to get one step ahead. More tokens will be supported in subsequent updates.'
      },
      {
        title: 'Which wallet is supported by PNS?',
        text: 'PNS supports MetaMask wallet. More wallets will be supported in subsequent updates.'
      },
      {
        title: 'Why does PNS set the limitation and not open all the domains?',
        text: 'Our goal was never to create barriers for users to get their wanted names, but to prevent some domain-hunters to get a lot of domains when PNS is not known by others, and newcomers find it hard to get what they want, which eventually jeopardizes the future for this community, and everyone who holds this domain.'
      }
    ]
  },
  {
    category: 'Creating or deleting a subdomain',
    list: [
      {
        title: 'What is a subdomain?',
        text: 'For example, if the domain name for PNS is PNS.dot, then we can create its subdomains as 1.PNS.dot, or 2.PNS.dot, etc.'
      },
      {
        title: 'How can the subdomain be used?',
        text: 'Take PNS.dot as an example. Suppose 1.PNS.dot is one of its subdomains. In terms of function, 1.PNS.dot is no different from PNS.dot, except that no subdomain can be created under 1.PNS.dot. All operations of generating and modifying subdomains require a very small amount of gas fee.'
      },
      {
        title: 'How many subdomains can be created for each domain name?',
        text: 'At present, for one domain name, the number of subdomains is limited to 20. In the future, there will be no upper limit.'
      },
      {
        title: 'How to transfer subdomains to other people?',
        text: 'It is the same as the transfer of domain name, you can click the “transfer” and set a target address for the transfer on the editing page of subdomain.'
      }
    ]
  },
  {
    category: 'How to use the Web3Profile',
    list: [
      {
        title: 'How to access the Web3Profile?',
        text: 'You can add .site after the domain name. For example, for the domain name PNS.dot, the Web3Profile home page address is PNS.dot.site. You can also click the "Get your Web3Profile" link on the domain name home page to access it.'
      },
      {
        title: 'Where do the NFTs shown on the Web3Profile come from?',
        text: 'These NFTs are from the platforms supported by PNS and read by the ETH address you connected to the domain. '
      },
      {
        title: 'Can the personal avatar and background be changed?',
        text: 'It is not supported by the current version for now, but there are future plans to update these features.'
      }
    ]
  },
  {
    category: 'How to use the PNS Redeem code',
    list: [
      {
        title: 'How to get the PNS Redeem code?',
        text: 'At present, we mainly give PNS Redeem tickets through community activities, AMA activities, etc. Please follow our event updates on Twitter and Discord if you’d like to hunt them.'
      },
      {
        title: 'What needs to be noticed when using the PNS Redeem code?',
        text: '1. The validity of the redeem code is 7 days. Please register your domain in time.\n 2. Make sure you have a small amount of GLMR for the gas fee in your wallet. This is important, if you failed to pay the gas fee, you may waste the Redeem Ticket.'
      },
      {
        title: 'What is the validity period of the domain name registered by Redeem ticket?',
        text: 'Normally, the Redeem ticket includes the registration fee and the annual fee for the first year. After one year, you can arrange the renewal fee if you’d like to keep this domain.'
      }
    ]
  }
]

const CN = [
  {
    category: '怎么注册 PNS 域名',
    list: [
      {
        title: 'PNS 的注册费和年费分别是多少?',
        text: '9位及以上的域名，注册费为20刀，年费为5刀'
      },
      {
        title: '怎么添加 Moonbeam 网络？',
        text: '复制下面链接到浏览器打开\n <a href="https://medium.com/moonbeam-network/how-to-connect-metamask-to-moonbeam-10a70d631735">https://medium.com/moonbeam-network/how-to-connect-metamask-to-moonbeam-10a70d631735</a>'
      },
      {
        title: '怎么样添加 MetaMask 到谷歌浏览器？',
        text: '复制下面链接到浏览器打开<a href="https://metamask.io/faqs/">https://metamask.io/faqs/</a>'
      },
      {
        title: '目前注册 PNS 的限制是什么？',
        text: '目前已经开放8位及以上域名注册，合法字符包括：英文字母（不区分大小写），数字和连接符(hyphen，- )，连接符不能多个重复，不能在首尾。'
      },
      {
        title: '目前 PNS 支持哪些币种付款?',
        text: '我们目前支持glmr 付款，更多的币种支持我们将尽快上线。'
      },
      {
        title: '目前 PNS 支持哪些钱包?',
        text: '目前我们支持metamask 钱包，更多的钱包支持将尽快上线。'
      },
      {
        title: '为什么不开放所有域名?',
        text: '我们的目标不是为设置障碍，而是防止用户在 PNS 不为他人所知的情况下获得大量域名，而后来的发现者发现他们很难获得他们想要的东西，这最终会危及这个社区的未来，以及拥有这个域名的每个人。'
      }
    ]
  },
  {
    category: '增减子域名',
    list: [
      {
        title: '什么是子域名?',
        text: '比如我们公司的域名为pns.dot，我们可以创建子域名为1.pns.dot ，或者2.pns.dot 以此类推'
      },
      {
        title: '子域名有什么作用？',
        text: '以 pns.dot 举例，增加子域名1.pns.dot 后。在功能上1.pns.dot 除了没有增加下一级子域名的功能外，其他功能和pns.dot 相同。值得注意的是所有生成和修改子域名的操作都需要付一定的gas费哟'
      },
      {
        title: '每个域名可以设置多少个子域名？',
        text: '目前每个域名的数量限定是20，后续将不设上限。'
      },
      {
        title: '如何转增子域名给他人?',
        text: '和域名的transfer是一样的，可以参考域名的转移。'
      }
    ]
  },
  {
    category: 'Web3Profile 的使用',
    list: [
      {
        title: '怎么进入 Web3Profile?',
        text: '可以在域名后加 .site，如域名 PNS.dot 的 Web3profile主页地址就是 PNS.dot.site，也可以在域名主页旁点击小角标进入。'
      },
      {
        title: 'Web3Profile 显示的 NFT 来自哪里?',
        text: '来自于你域名绑定的钱包下的位于我们所支持的平台的 NFT。'
      },
      {
        title: '个人头像及背景可以修改吗？',
        text: '目前版本暂且不支持，但是已经有计划更新这些功能。'
      }
    ]
  },
  {
    category: '兑换码的使用',
    list: [
      {
        title: '怎么获得兑换码?',
        text: '目前我们主要通过社区活动、AMA 活动等赠送兑换码给用户，如果你想要获得兑换码可以多多关注我们的社区活动。'
      },
      {
        title: '兑换码在使用时需要注意什么？',
        text: '1.兑换码的有效期是7天，您获得兑换码后请尽快注册\n 2.当您使用兑换码的时候，请确保钱包内的余额可以支付gas fee。如果在使用对话码后，gas fee 没有支付成功，可能会导致兑换码失效。'
      },
      {
        title: '兑换码兑换的域名有效期是多久?',
        text: '一般情况下，兑换码包含注册费和首年年费，一年后您可以自行安排续费。'
      }
    ]
  }
]

export const helps = { EN, CN }
